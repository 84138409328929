import React from 'react';

class Dates extends React.Component {
    render(){
        return (
            <div className = "datesContent">
                <div className="pageTitle"><h2>Litter Pick Dates</h2></div>
                <div className="pageContent">
                    <p>The following dates have been scheduled for 2024.</p>
                    <p>All dates are subject to change at short notice - adverse weather may require last minute changes or cancellations.</p>
                    <p>Please check before attending. Updates will be sent to the email list and posted on social media.</p>
                    <br /><br/>
                    <div className="desktop">
                        <table aria-label="Event dates">
                            <thead>
                                <tr>
                                    <th>Day</th>
                                    <th>Date</th>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Event Page</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="past">
                                    <td>Saturday</td>
                                    <td>27th January</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                    <td><a href="https://fb.me/e/3o6W3CUW9" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Event Page</a></td>
                                </tr>
                                <tr className="past">
                                    <td>Saturday</td>
                                    <td>CANCELLED DUE TO WEATHER <br/>24th February</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                    <td><a href="https://fb.me/e/33EX8mTJ9" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Event Page</a></td>
                                </tr>
                                <tr className="past">
                                    <td>Saturday</td>
                                    <td>30th March</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                    <td><a href="https://fb.me/e/424KfIBJz" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Event Page</a></td>
                                </tr>
                                <tr  className="past">
                                    <td>Saturday</td>
                                    <td>27th April</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                    <td><a href="https://fb.me/e/1FpsrlNug" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Event Page</a></td>
                                </tr>
                                <tr className="past">
                                    <td>Saturday</td>
                                    <td>18th May</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                    <td><a href="https://fb.me/e/1eRqQnBCn" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Event Page</a></td>
                                </tr>
                                <tr className="past">
                                    <td>Saturday</td>
                                    <td>29th June</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                    <td><a href="https://fb.me/e/1HX3Sx5ZR" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Event Page</a></td>
                                </tr>
                                <tr className="past">
                                    <td>Saturday</td>
                                    <td>20th July</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                    <td><a href="https://fb.me/e/gfAJaxJA5" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Event Page</a></td>
                                </tr>
                                <tr className="past">
                                    <td>Saturday</td>
                                    <td>CANCELLED due to weather <br/>24th August</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                    <td><a href="https://fb.me/e/3gBpNXEoM" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Event Page</a></td>
                                </tr>
                                <tr className="next">
                                    <td>Saturday</td>
                                    <td>28th September</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                    <td><a href="https://fb.me/e/1d6Vpvept" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Event Page</a></td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>26th October</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                    <td><a href="https://fb.me/e/1QyPwG08M" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Event Page</a></td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>23rd November</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                    <td><a href="https://fb.me/e/7wmXlCm05" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Event Page</a></td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>14th December</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                    <td><a href="https://fb.me/e/1sEgu9Ao9" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Event Page</a></td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>25th January 2025</td>
                                    <td>10am</td>
                                    <td>12pm</td>
                                    <td><a href="https://fb.me/e/44GZlIU3l" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Event Page</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                    </div>
                    <div className="mobile">
                        <p className="dateLine past">
                            Saturday 27th January
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                            <a href="https://fb.me/e/3o6W3CUW9" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Facebook Event Page</a>
                        </p>
                        <p className="dateLine past">
                            Saturday 24th February - CANCELLED DUE TO WEATHER
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                            <a href="https://fb.me/e/33EX8mTJ9" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Facebook Event Page</a>
                        </p>
                        <p className="dateLine past">
                            Saturday 30th March
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                            <a href="https://fb.me/e/424KfIBJz" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Facebook Event Page</a>
                        </p>
                        <p className="dateLine past">
                            Saturday 27th April
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                            <a href="https://fb.me/e/1FpsrlNug" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Facebook Event Page</a>
                        </p>
                        <p className="dateLine past">
                            Saturday 18th May
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                            <a href="https://fb.me/e/1eRqQnBCn" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Facebook Event Page</a>
                        </p>
                        <p className="dateLine past">
                            Saturday 29th June
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                            <a href="https://fb.me/e/1HX3Sx5ZR" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Facebook Event Page</a>
                        </p>
                        <p className="dateLine past">
                            Saturday 20th July
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                            <a href="https://fb.me/e/gfAJaxJA5" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Facebook Event Page</a>
                        </p>
                        <p className="dateLine past">
                            Saturday 24th August - CANCELLED due to weather
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                            <a href="https://fb.me/e/3gBpNXEoM" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Facebook Event Page</a>
                        </p>
                        <p className="dateLine next">
                            Saturday 28th September
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                            <a href="https://fb.me/e/1d6Vpvept" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Facebook Event Page</a>
                        </p>
                        <p className="dateLine">
                            Saturday 26th October
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                            <a href="https://fb.me/e/1QyPwG08M" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Facebook Event Page</a>
                        </p>
                        <p className="dateLine">
                            Saturday 23rd November
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                            <a href="https://fb.me/e/7wmXlCm05" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Facebook Event Page</a>
                        </p>
                        <p className="dateLine">
                            Saturday 14th December
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                            <a href="https://fb.me/e/1sEgu9Ao9" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Facebook Event Page</a>
                        </p>
                        <p className="dateLine">
                            Saturday 25th January 2025
                            <br/>
                            10.00am - 12.00pm
                            <br/>
                            <a href="https://fb.me/e/44GZlIU3l" target="_blank" rel="noopener noreferrer" title="Facebook event link (New Window)">Facebook Event Page</a>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dates;